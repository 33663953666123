/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
function googleTranslateElementInit2() {
    new google.translate.TranslateElement({ pageLanguage: "fr", autoDisplay: false }, "google_translate_element2");
}
if (!window.gt_translate_script) {
    window.gt_translate_script = document.createElement("script");
    gt_translate_script.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit2";
    document.body.appendChild(gt_translate_script);
}


        function GTranslateGetCurrentLang() {
    var keyValue = document.cookie.match("(^|;) ?googtrans=([^;]*)(;|$)");
    return keyValue ? keyValue[2].split("/")[2] : null;
}
function GTranslateFireEvent(element, event) {
    try {
        if (document.createEventObject) {
            var evt = document.createEventObject();
            element.fireEvent("on" + event, evt);
        } else {
            var evt1 = document.createEvent("HTMLEvents");
            evt1.initEvent(event, true, true);
            element.dispatchEvent(evt1);
        }
    } catch (e) {}
}
function doGTranslate(lang_pair) {
    if (lang_pair.value){lang_pair = lang_pair.value;}
    if (lang_pair === ""){ return ;}
    var lang = lang_pair.split("|")[1];
    if (new GTranslateGetCurrentLang() === null && lang === lang_pair.split("|")[0]){ return;}
    if (typeof ga === "function") {
        ga("send", "event", "GTranslate", lang, location.hostname + location.pathname + location.search);
    }
    var teCombo;
    var sel = document.getElementsByTagName("select");
    for (var i = 0; i < sel.length; i++){
        if (sel[i].className.indexOf("goog-te-combo") !== -1) {
            teCombo = sel[i];
            break;
        }
    }
    if (document.getElementById("google_translate_element2") === null || document.getElementById("google_translate_element2").innerHTML.length === 0 || teCombo.length === 0 || teCombo.innerHTML.length === 0) {
        setTimeout(function () {
            doGTranslate(lang_pair);
        }, 500);
    } else {
        teCombo.value = lang;
        new GTranslateFireEvent(teCombo, "change");
        new GTranslateFireEvent(teCombo, "change");
    }
}
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
